/* Estilos para la tabla */
.grid-view table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Estilos para las celdas de la tabla */
  .grid-view th, .grid-view td {
    padding: 8px;
    border: 1px solid #ddd; /* Añadir bordes a las celdas */
  }
  
  /* Estilos para las celdas de encabezado */
  .grid-view th {
    background-color: #f2f2f2; /* Color de fondo para las celdas de encabezado */
  }
  
  /* Estilos para las filas impares */
  .grid-view tbody tr:nth-child(odd) {
    background-color: #f9f9f9; /* Color de fondo para las filas impares */
  }
  
  /* Estilos para los botones */
  .grid-view button {
    margin-right: 5px;
  }
  