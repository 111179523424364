.detail {
  display: flex;
  height: 100%;
  background-color: rgba(18, 120, 189, 0.05);
  .detailContainer {
    flex: 6;
    &.collapsed {
      flex: 14;
    }
    .top {
      padding: 50px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        padding: 20px;
        position: relative;
        background-color: #fff;
        .top-info {
          display: flex;
          justify-content: space-between; // This will put maximum space between flex children
          padding: 20px;
          .fecha {
            color: rgba(116, 120, 141, 0.50);
            margin-left: auto; // This pushes the .fecha element to the far right
            text-align: right; // This aligns the text to the right within its container     
            font-size: 18px;
          }
        }
        .editButton {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 12px;
          color: #7451f8;
          background-color: #7551f818;
          cursor: pointer;
          border-radius: 0px 0px 0px 5px;
        }

        .itemsContainer {
          padding: 0 20px 0 20px;
          .detailItem{
            padding: 5px;
          }
        }

        .itemKey {
          font-weight: bold;
          color: #495057;
          margin-right: 5px;
        }

        .itemValue {
          font-weight: 300;
          color: #495057;
        }

        .formImg {
          width: 300px;
          height: 300px;
          object-fit: cover;
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 30px 20px;
    }

    .title {
      text-transform: uppercase;
      font-size: 35px;
      color: #0e77bd;
      padding: 60px 0 10px 80px;
    }

    .vigilante {
      font-size: 22px;
      font-weight: bold;
      color: #0e77bd;
    }
    .observaciones{
      margin-left: 20px;
    }
    .fecha {
      font-size: 20px;
      margin-left: 0; // Align to the far left
    }
  }

}
