.calendar-container {
    margin: 20px 0;
  }
  
  .calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2px;
  }
  
  .calendar-day {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    cursor: pointer;
    background-color: #f8f8f8;
    transition: all 0.2s ease;
  }
  
  .calendar-day:hover {
    background-color: #e0e0e0;
  }
  
  .calendar-day.empty {
    background-color: transparent;
    border: none;
    cursor: default;
  }
  
  .calendar-day.selected {
    background-color: #1976d2;
    color: white;
  }
  
  .calendar-day.selected:hover {
    background-color: #1565c0;
  }