.companies-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;

    .companies-title {
        margin-bottom: 30px;
        color: #333;
    }

    .company-form-paper {
        padding: 20px;
        margin-bottom: 30px;

        .company-form {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .logo-upload {
                margin: 20px 0;

                .logo-preview {
                    margin-top: 10px;
                    max-width: 200px;
                    
                    img {
                        width: 100%;
                        height: auto;
                        object-fit: contain;
                    }
                }
            }

            .submit-button {
                margin-top: 20px;
            }
        }
    }

    .companies-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 20px;

        .company-paper {
            padding: 20px;

            .company-info {
                display: flex;
                gap: 20px;
                align-items: center;

                .company-logo {
                    width: 80px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .company-details {
                    flex: 1;
                }
            }
        }
    }
}